@import '../../styles/variables';

.page.contact {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-container {
    margin-bottom: 1rem;
  }

  .email-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    a {
      font-size: 1.6rem;
      text-decoration: none;
      color: #000;

      @media screen and (max-width: 500px) {
        font-size: 1.25rem;
      }

      &:hover {
        color: $theme-color;
      }
    }

    .social {
      display: flex;
      align-items: center;
      @media screen and (max-width: 500px) {
        margin: 25px 0 0 0;
      }
      .instagram {
        margin: 0 25px 0 50px;

        @media screen and (max-width: 500px) {
          margin: 0px 25px 0 0;
        }
      }
    }
  }
}

.photo {
  display: block;
  width: 100%;
  position: relative;
  top: 100px;
  opacity: 0;
  transition: 0.75s ease;
  transform: translateZ(1);

  &.loaded {
    // // opacity: 1;
  }

  &.visible {
    // animation-delay: 0.5s;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-name: slideup;
  }

  @keyframes slideup {
    from {
      opacity: 0;
      top: 100px;
    }

    to {
      opacity: 1;
      top: 0px;
    }
  }
}

@import './reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Lora&display=swap');

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	font-family: 'Comfortaa', cursive;
	// font-family: 'Cutive Mono', monospace;
	color: #000;
}

.page {
	position: relative;
	padding: 3vw;
}

.instagram,
.youtube {
	display: block;
	// width: 20px;
}

.error {
	color: red;
}

p,
li {
	font-size: 1rem;
}

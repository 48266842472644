*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}
html:focus-within {
	scroll-behavior: smooth;
}

body {
	// min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

input,
button,
textarea,
select {
	font: inherit;
}

img {
	display: block;
	max-width: 100%;
}
